import { commonModuleGroup, podcastModuleGroup } from "modules/groups";
import {
  API_ACCESS_MODULE,
  ADMIN_MODULE,
  AUTH_MODULE,
  CATEGORY_MODULE,
  CLAIMING_MODULE,
  CREATOR_EDIT_MODULE,
  CREATOR_VIEW_MODULE,
  INTEGRATION_MODULE,
  PAGINATION_MODULE,
  SEARCH_MODULE,
  TIMELINE_MODULE,
  TAG_MODULE,
  USER_MODULE,
  USERLIST_MODULE,
  USERLIST_COMMON_MODULE,
  USER_PROFILE_MODULE,
  VANITY_URL_MODULE,
  MAGIC_LINK_MODULE,
} from "modules/moduleIds";
import PropTypes from "prop-types";
import { Redirect, Switch, Route } from "react-router-dom";

import SocialLoginLoading from "components/Auth/SocialLoginLoading";
import RegisteredRoute from "components/Common/RegisteredRoute";
import AccountManagementContainer from "pages/Account/Settings/async";
import VerifyEmailToken from "pages/Account/Settings/VerifyEmailTokenAsync";
import VerifyMagicLink from "pages/Account/Settings/VerifyMagicLinkAsync";
import AdminSection from "pages/Admin/async";
import RegistrationRequired from "pages/Auth/RegistrationRequired";
import TopLevelRoute from "pages/Common/TopLevelRoute";
import Leaderboard from "pages/Leaderboard/async";
import UserListViewSkeleton from "pages/UserListView/UserListViewSkeleton";
import UserProfile from "pages/UserProfile/async";
import Top8Editor from "pages/UserProfile/Top8Editor/async";

import ColourTestPage from "../styles/Tests/ColourTestPageAsync";
import TokenTestPage from "../styles/Tests/TokenTestPageAsync";
import { BLOG_BASE_PATH } from "../utils/blog";
import AboutView from "./About/async";
import AccountHubContainer from "./Account/Hub/async";
import LandingPage from "./AdvertiseOnPodcasts/LandingPageAsync";
import AlertOverview from "./Alerts/AlertsOverviewAsync";
import PasswordReset from "./Auth/PasswordReset/async";
import BlogRoutes from "./BlogRoutes";
import BrandRoutes from "./BrandView/BrandRoutes";
import CategorySearch from "./CategorySearch/async";
import CategoryView from "./CategoryView/CategoryViewAsync";
import AddPodcast from "./Claim/AddPodcast/async";
import ClaimPodcast from "./Claim/ClaimPodcast/async";
import ValidatePodcastClaim from "./Claim/ValidatePodcastClaim/async";
import CreatorRoutes from "./CreatorRoutes";
import ChartRoutes from "./Discover/Charts/ChartsRoutes";
import Episodes from "./Discover/Episodes/async";
import UserList from "./Discover/UserList/async";
import HomepageView from "./Homepage/async";
import IntegrationsLeaderboard from "./IntegrationsLeaderboard/async";
import MonitoringReportPage from "./Monitoring/MonitoringReportPageAsync";
import NetworkRoutes from "./Networks/NetworkRoutes";
import PodcastRoutes from "./PodcastRoutes";
import ProfileRoutes from "./ProfileRoutes";
import SearchResults from "./SearchResults/async";
import TagView from "./TagView/async";
import UserListHub from "./UserListsHub/async";
import UserListView from "./UserListView/async";
import VanityURL from "./VanityUrl/VanityURLAsync";

import { sortKeyRegex } from "constants/sort";
import getUserUrl from "utils/entity/getUserUrl";

import useIsNewFullWidth from "hooks/useIsNewFullWidth";
import useLoggedInUser from "hooks/useLoggedInUser";
import useWindowSize from "hooks/useWindowSize";

import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const TRANSPARENT_PROPS = { transparentPadding: true };
const homepageStyles = {
  topHeaderPlaceholder: {
    [ScreenSizes.lgAndAbove]: {
      minHeight: 0,
    },
  },
};

const listPageStyles = {
  topHeaderPlaceholder: {
    minHeight: "4.5rem",

    [ScreenSizes.lgAndAbove]: {
      minHeight: 0,
    },

    [createBreakpoint({ min: 1400 })]: {
      minHeight: 0,
    },
  },
};

const headerNavWrapperStyles = {
  container: {
    [ScreenSizes.lgAndAbove]: {
      width: "100%",
      background: "none",
      boxShadow: "none",
      zIndex: 15,
    },
  },
  secondNav: {
    [ScreenSizes.lgOnly]: {
      position: "relative",
      display: "block",
    },
    [createBreakpoint({ min: 1400 })]: {
      display: "none",
    },
  },
};

const CATEGORIES_HEIGHT = 5600;

const CATEGORIES_LOADING_STYLES = {
  height: {
    height: "350rem",
    maxHeight: "350rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "280rem",
      maxHeight: "280rem",
    },
  },
};

const SPECIFIC_CATEGORY_HEIGHT = 6400;

const SPECIFIC_CATEGORY_LOADING_STYLES = {
  height: {
    height: "406rem",
    maxHeight: "406rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "350rem",
      maxHeight: "350rem",
    },
  },
};

const HOMEPAGE_HEIGHT = 5600;

const HOMEPAGE_LOADING_STYLES = {
  height: {
    height: "231.25rem",
    maxHeight: "231.25rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "350rem",
      maxHeight: "350rem",
    },
  },
};

function AppRoutes(props) {
  const { errorPage, onMenuToggleClick } = props;

  const topLevelRouteProps = {
    onMenuToggleClick,
    errorPage,
  };

  const { isWindowSizeOrMore, isWindowSizeOrLess } = useWindowSize();
  const loggedInUser = useLoggedInUser();

  const isMedium = isWindowSizeOrLess("medium");
  const isDesktop = isWindowSizeOrMore("medium");
  const isNewFullWidth = useIsNewFullWidth();

  return (
    <Switch>
      <TopLevelRoute
        path="/(popular||myfeed|myfeed_filtered|trending|more)?"
        exact
        {...topLevelRouteProps}
        styles={homepageStyles}
        height={HOMEPAGE_HEIGHT}
        loadingStyles={HOMEPAGE_LOADING_STYLES}
        headerNavWrapperStyles={headerNavWrapperStyles}
        hideHeader={false}
        isHomepage
        hideUnlockProFooterBanner
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          USER_PROFILE_MODULE,
          USERLIST_MODULE,
          TIMELINE_MODULE,
          INTEGRATION_MODULE,
          CLAIMING_MODULE,
          CREATOR_VIEW_MODULE,
        ]}
      >
        <HomepageView />
      </TopLevelRoute>

      <TopLevelRoute
        path="/(google|facebook|twitter)/auth"
        exact
        {...topLevelRouteProps}
        modules={[...commonModuleGroup, PAGINATION_MODULE]}
      >
        <SocialLoginLoading />
      </TopLevelRoute>

      <TopLevelRoute
        path="/about"
        exact
        {...topLevelRouteProps}
        modules={[...commonModuleGroup, ...podcastModuleGroup]}
      >
        <AboutView />
      </TopLevelRoute>

      <Route path="/podcast(s?)">
        <PodcastRoutes
          isMedium={isMedium}
          topLevelRouteProps={topLevelRouteProps}
        />
      </Route>

      <TopLevelRoute
        path={`/episodes/:sort(${sortKeyRegex})?`}
        exact
        {...topLevelRouteProps}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          CREATOR_VIEW_MODULE,
          USERLIST_COMMON_MODULE,
        ]}
      >
        <Episodes />
      </TopLevelRoute>

      <TopLevelRoute
        path={`/categor(ies|y)/:tag_slug/:type?/:sort(${sortKeyRegex})?`}
        {...topLevelRouteProps}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          CREATOR_VIEW_MODULE,
          USERLIST_COMMON_MODULE,
          TAG_MODULE,
        ]}
        height={SPECIFIC_CATEGORY_HEIGHT}
        loadingStyles={SPECIFIC_CATEGORY_LOADING_STYLES}
      >
        <CategoryView />
      </TopLevelRoute>

      <TopLevelRoute
        path="/categories"
        {...topLevelRouteProps}
        modules={[...commonModuleGroup, CATEGORY_MODULE]}
        height={CATEGORIES_HEIGHT}
        loadingStyles={CATEGORIES_LOADING_STYLES}
      >
        <CategorySearch />
      </TopLevelRoute>

      <TopLevelRoute
        path="/alerts"
        {...topLevelRouteProps}
        modules={[...commonModuleGroup, CATEGORY_MODULE]}
      >
        <AlertOverview />
      </TopLevelRoute>

      <TopLevelRoute
        path="/advertise"
        {...topLevelRouteProps}
        modules={[...commonModuleGroup, CATEGORY_MODULE]}
      >
        <LandingPage />
      </TopLevelRoute>

      <TopLevelRoute
        path="/monitoring-report"
        {...topLevelRouteProps}
        modules={[...commonModuleGroup, CATEGORY_MODULE]}
      >
        <MonitoringReportPage />
      </TopLevelRoute>

      <TopLevelRoute
        path={`/tag(s?)/:tag_slug/:type?/:sort(${sortKeyRegex})?`}
        {...topLevelRouteProps}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          CREATOR_VIEW_MODULE,
          USERLIST_COMMON_MODULE,
          TAG_MODULE,
        ]}
      >
        <TagView tagType="tag" />
      </TopLevelRoute>

      <TopLevelRoute
        path={isNewFullWidth ? "/about/lists" : "/lists"}
        exact
        styles={listPageStyles}
        modules={[
          ...commonModuleGroup,
          USER_PROFILE_MODULE,
          USERLIST_COMMON_MODULE,
        ]}
        height={"136rem"}
        {...topLevelRouteProps}
      >
        <UserListHub />
      </TopLevelRoute>

      {isNewFullWidth && (
        <TopLevelRoute
          path={`/list(s?)/:sort(${sortKeyRegex})?`}
          exact
          modules={[
            ...commonModuleGroup,
            USER_PROFILE_MODULE,
            USERLIST_COMMON_MODULE,
          ]}
          {...topLevelRouteProps}
        >
          <UserList />
        </TopLevelRoute>
      )}

      <TopLevelRoute
        path="/list(s?)/:id"
        exact
        styles={listPageStyles}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          USERLIST_MODULE,
          CREATOR_VIEW_MODULE,
          USER_PROFILE_MODULE,
          TIMELINE_MODULE,
        ]}
        height={"136rem"}
        asyncComponent={<UserListViewSkeleton />}
        {...topLevelRouteProps}
      >
        <UserListView />
      </TopLevelRoute>

      <TopLevelRoute
        path="/leaderboard/:type?"
        modules={[...commonModuleGroup]}
        {...topLevelRouteProps}
      >
        <Leaderboard />
      </TopLevelRoute>

      <TopLevelRoute
        path="/search/:type/q/:term/"
        {...topLevelRouteProps}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          CREATOR_VIEW_MODULE,
          USERLIST_COMMON_MODULE,
        ]}
      >
        <SearchResults />
      </TopLevelRoute>

      <TopLevelRoute
        path="/search/:type/"
        {...topLevelRouteProps}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          CREATOR_VIEW_MODULE,
          USERLIST_COMMON_MODULE,
        ]}
      >
        <SearchResults />
      </TopLevelRoute>

      <Route path="/creators">
        <CreatorRoutes
          isDesktop={isDesktop}
          topLevelRouteProps={topLevelRouteProps}
          isNewFullWidth={isNewFullWidth}
        />
      </Route>

      <Route path="/networks">
        <NetworkRoutes isDesktop={isDesktop} />
      </Route>

      <Route path="/charts">
        <ChartRoutes isDesktop={isDesktop} />
      </Route>

      <Route path="/brands">
        <BrandRoutes isDesktop={isDesktop} />
      </Route>

      <TopLevelRoute
        path="/pcadmin"
        routeComponent={RegisteredRoute}
        notRegisteredComponent={RegistrationRequired}
        notAuthorizedComponent={() => <div>Not Authorized</div>}
        requiredPermissions={["view admin"]}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          CREATOR_VIEW_MODULE,
          CREATOR_EDIT_MODULE,
          ADMIN_MODULE,
        ]}
      >
        <AdminSection />
      </TopLevelRoute>

      <TopLevelRoute
        path="/claim"
        exact
        {...topLevelRouteProps}
        modules={[...podcastModuleGroup, CLAIMING_MODULE]}
      >
        <ClaimPodcast />
      </TopLevelRoute>

      <TopLevelRoute
        path="/add"
        exact
        {...topLevelRouteProps}
        modules={[...podcastModuleGroup, CLAIMING_MODULE]}
      >
        <AddPodcast />
      </TopLevelRoute>

      <TopLevelRoute
        routeComponent={RegisteredRoute}
        path="/account"
        notRegisteredComponent={RegistrationRequired}
        {...topLevelRouteProps}
        modules={[...podcastModuleGroup, CLAIMING_MODULE]}
        withNavMenu={false}
      >
        <AccountHubContainer />
      </TopLevelRoute>

      <TopLevelRoute
        routeComponent={RegisteredRoute}
        path="/account/settings/api"
        notRegisteredComponent={RegistrationRequired}
        actionTitle="Login or register to access your API credentials."
        {...topLevelRouteProps}
        modules={[API_ACCESS_MODULE]}
      >
        <AccountManagementContainer />
      </TopLevelRoute>

      <Route path={BLOG_BASE_PATH}>
        <BlogRoutes topLevelRouteProps={topLevelRouteProps} />
      </Route>

      <RegisteredRoute
        path="/(users/)?me"
        notRegisteredComponent={RegistrationRequired}
        notAuthorizedComponent={() => <div>Not Authorized</div>}
      >
        <Redirect to={getUserUrl(loggedInUser)} />
      </RegisteredRoute>

      <TopLevelRoute
        path={["/users/:id"]}
        {...topLevelRouteProps}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          USER_PROFILE_MODULE,
          CREATOR_VIEW_MODULE,
          USERLIST_COMMON_MODULE,
          SEARCH_MODULE,
          TIMELINE_MODULE,
          INTEGRATION_MODULE,
        ]}
      >
        <UserProfile />
      </TopLevelRoute>

      {/* Should only be linked to externally */}
      <TopLevelRoute
        path="/top8"
        {...topLevelRouteProps}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          USER_PROFILE_MODULE,
        ]}
        withHeader={false}
        withFooter={false}
      >
        <Top8Editor />
      </TopLevelRoute>

      <Route path="/profile">
        <ProfileRoutes
          isDesktop={isDesktop}
          topLevelRouteProps={topLevelRouteProps}
        />
      </Route>

      <TopLevelRoute
        path="/mytop8"
        {...topLevelRouteProps}
        passProps={TRANSPARENT_PROPS}
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          USER_PROFILE_MODULE,
        ]}
        transparent
      >
        <Top8Editor />
      </TopLevelRoute>

      <TopLevelRoute
        path="/reset_password"
        {...topLevelRouteProps}
        modules={[AUTH_MODULE]}
      >
        <PasswordReset />
      </TopLevelRoute>

      <TopLevelRoute
        routeComponent={RegisteredRoute}
        path="/podcast_claim/:token"
        exact
        notRegisteredComponent={RegistrationRequired}
        modules={[...commonModuleGroup, ...podcastModuleGroup, CLAIMING_MODULE]}
        {...topLevelRouteProps}
      >
        <ValidatePodcastClaim />
      </TopLevelRoute>

      <TopLevelRoute
        path="/verify_email/:token"
        exact
        modules={[...commonModuleGroup, ...podcastModuleGroup, USER_MODULE]}
        {...topLevelRouteProps}
      >
        <VerifyEmailToken />
      </TopLevelRoute>

      <TopLevelRoute
        path="/verify_magic_link/:token"
        exact
        modules={[...commonModuleGroup, USER_MODULE, MAGIC_LINK_MODULE]}
        {...topLevelRouteProps}
      >
        <VerifyMagicLink />
      </TopLevelRoute>

      <RegisteredRoute
        path="/tests/colours"
        exact
        notRegisteredComponent={RegistrationRequired}
        notAuthorizedComponent={() => <div>Not Authorized</div>}
        modules={[...commonModuleGroup]}
        {...topLevelRouteProps}
      >
        <ColourTestPage />
      </RegisteredRoute>

      <RegisteredRoute
        path="/tests/tokens"
        exact
        notRegisteredComponent={RegistrationRequired}
        notAuthorizedComponent={() => <div>Not Authorized</div>}
        modules={[...commonModuleGroup]}
        {...topLevelRouteProps}
      >
        <TokenTestPage />
      </RegisteredRoute>

      <TopLevelRoute
        path="/integrations"
        exact
        modules={[...commonModuleGroup, USER_MODULE, INTEGRATION_MODULE]}
        {...topLevelRouteProps}
      >
        <IntegrationsLeaderboard />
      </TopLevelRoute>

      <TopLevelRoute
        path="/:vanity_url"
        modules={[
          ...commonModuleGroup,
          ...podcastModuleGroup,
          CATEGORY_MODULE,
          VANITY_URL_MODULE,
        ]}
        {...topLevelRouteProps}
      >
        <VanityURL with404 />
      </TopLevelRoute>
    </Switch>
  );
}

AppRoutes.propTypes = {
  hidden: PropTypes.bool,
  errorPage: PropTypes.node,
  onMenuToggleClick: PropTypes.func.isRequired,
};

AppRoutes.defaultProps = {
  hidden: false,
  errorPage: null,
};

export default AppRoutes;
